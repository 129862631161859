<template>
    <!--多用户添加奖惩-->
    <div>
        <el-dialog :title="operation ? '添加' : (optType?'编辑':'查看')" :visible.sync="dlgVisible" v-model="dlgVisible" :close-on-click-modal="false">
            <el-form ref="dlgFrom" :model="dlgFrom" class="demo-form-inline" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="姓名:" prop="UserName" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" :disabled="true" v-model="dlgFrom.UserName">
                                <el-button slot="append" icon="el-icon-search" @click="chooseUser" v-if="operation"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-tag v-for="tag in dlgFrom.UserList" :key="key" closable type="success" @close="handleClose(tag)">
                            {{tag.UserName}}
                        </el-tag>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="发布公司:" prop="IssuedDepartment" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="dlgFrom.IssuedDepartment">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="评定日期:" prop="ReleaseDate" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-date-picker type="date" v-model="dlgFrom.ReleaseDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="执行日期:" prop="ExecuteDate" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-date-picker type="date" v-model="dlgFrom.ExecuteDate" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="执行方式:" prop="ExecuteType" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-select v-model="dlgFrom.ExecuteType" placeholder="请选择" style="width: 100%">
                                <el-option label="无" value="无"></el-option>
                                <el-option label="现金" value="现金"></el-option>
                                <el-option label="工资体现" value="工资体现"></el-option>
                                <el-option label="内购卷" value="内购卷"></el-option>
                                <el-option label="资金池" value="资金池"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="类型:" prop="Type" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-select v-model="dlgFrom.Type" placeholder="请选择" style="width: 100%">
                                <el-option label="奖励" value="奖励"></el-option>
                                <el-option label="惩罚" value="惩罚"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="金额:" prop="Salary" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="dlgFrom.Salary" @blur="inputMoney('Salary')">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="具体描述">
                            <el-input type="textarea" v-model="dlgFrom.Explain"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                
                <upload-files :files="dlgFrom.FileArry" :key="key" action="/cyl/ftp/ftp/upload" :limit="20" @fun="dealFiles" :IsDisabled="operation" :IsDel="operation"></upload-files>
                <el-row v-if="operation">
                    <el-col :span="24" style="text-align: center">
                        <el-button type="primary" v-on:click="onSubmit('dlgFrom')">提交</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

        <el-dialog title="选择人员" :visible.sync="addUserVisible" v-model="addUserVisible" width="75%" center append-to-body>
            <UserChoose :data="choosedusers" :all="true" :single="false" @callback="usercallFunction"></UserChoose>
        </el-dialog>
    </div>
</template>

<script>
    import util from "../../../util/date";
    import * as validate from "../../../util/validate";
    import UserChoose from "../../components/UserChoose";
    import UploadFiles from "../../components/UploadFiles";
    import {
        AddListRewards,
    } from "../../api/hr";
    export default {
        components: {  UserChoose, UploadFiles },
        data() {
            return {
                optType: false,   // 编辑/查看
                operation: false, // true:新增, false:编辑
                key: 0,

                dlgVisible: false, // dlg 显示隐藏

                dlgFrom: {
                    UserId: "",
                    UserList:[],
                    UserName: "",
                    Type: "",
                    IssuedDepartment: "",
                    ExecuteType: "",
                    Salary: "",
                    Explain: "",
                    ExecuteDate: "",
                    FileArry: [],
                },
                addUserVisible: false,
                choosedusers: [],
            };
        },
        methods: {
            // 选中一行显示
            onShowDlg(pid) {
                if (pid == "" || pid == null) {
                    this.dlgFrom = {
                        UserId: "",
                        UserList: [],
                        UserName: "",
                        Type: "",
                        IssuedDepartment: "",
                        ExecuteType: "",
                        Salary: "",
                        Explain: "",
                        ExecuteDate: "",
                        FileArry: [],
                    };
                    this.key += 1;
                    this.dlgVisible = true;
                }
            },
            onSubmit(formName) {
                this.$confirm("确定要提交吗？", "提示", {}).then(() => {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let para = Object.assign({}, this.dlgFrom);
                            if (this.operation) {
                                //新增
                                AddListRewards(para).then((res) => {
                                    if (res.data.success) {
                                        this.$message({
                                            message: "添加成功",
                                            type: "success",
                                            duration: 5000,
                                        });
                                        this.dlgVisible = false;
                                        this.$emit("callback"); //将值传给父组件
                                    } else {
                                        this.$message({
                                            message: res.data.msg,
                                            type: "error",
                                            duration: 5000,
                                        });
                                        //this.dlgVisible = true;
                                    }
                                });
                            } else if (!this.operation) {
                                //修改
                            }
                        } else {
                            this.$message({
                                message: "请检查必填项",
                                type: "error",
                                duration: 5000,
                            });
                            return false;
                        }
                    });
                });
            },
            handleClose(tag) {
                this.dlgFrom.UserList.splice(this.dlgFrom.UserList.indexOf(tag), 1);
            },
            formatStartTime: function (row, colume) {
                var date = row[colume.property];
                if (date == undefined) {
                    return "";
                }
                return util.formatDate.format(new Date(date), "yyyy-MM-dd");
            },
            usercallFunction(newdata) {
                //if (newdata.length > 1) {
                //    this.$message({
                //        message: "不允许选择多人",
                //        type: "error",
                //    });
                //    return;
                //}
                this.dlgFrom.UserId = newdata[0].Id;
                this.dlgFrom.UserName = newdata[0].UserNameClaim;
                //多人人员添加位置
                for (var i = 0; i < newdata.length; i++) {
                    this.dlgFrom.UserList.push({ UserName: newdata[i].UserNameClaim, UserId: newdata[i].Id})
                }
                this.addUserVisible = false;
            },
            
            inputMoney(name) {
                if (!validate.validateIsMoney(this.dlgFrom[name])) {
                    this.$message({
                        message: "请输入金额",
                        type: "error",
                        duration: 5000,
                    });
                    this.dlgFrom[name] = null;
                    return;
                }
            },

            chooseUser() {
                this.addUserVisible = true;
            },
            
            callFunction(item) {
                this[item.Func].apply(this, item);
            },
            
            
            dealFiles(data) {
                this.dlgFrom.FileArry = data.backData;
            },
        },
        mounted() {
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            let buttons = window.localStorage.buttList
                ? JSON.parse(window.localStorage.buttList)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers, buttons);
        },
    };
</script>

<style scoped>
    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }
</style>